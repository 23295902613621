import { Injectable, signal } from '@angular/core';
import { IGuardianStudent } from '@shared/interfaces';
import { ObjId } from '@shared/interfaces/common.interface';
import { IIdentifiable } from '@shared/interfaces/identifiable.interface';

export interface StudentSelectionScope {
  id: number;
  fullName: string;
  nationalId: string;
  academicYear: IIdentifiable | null;

  school?: {
    id?: number;
    name?: string;
    level: IIdentifiable | null;
    class: IIdentifiable | null;
  } | null;
}

@Injectable({
  providedIn: 'root',
})
export class StudentSelectionScopeService {
  private readonly _studentSelectionScope = signal<
    Array<StudentSelectionScope>
  >([]);
  studentSelectionScope = this._studentSelectionScope.asReadonly();

  private readonly _selectedStudent = signal<StudentSelectionScope | null>(
    null,
  );
  selectedStudent = this._selectedStudent.asReadonly();

  updateStudentSelectionScope(students: Array<StudentSelectionScope>) {
    this._studentSelectionScope.set(students);
  }

  updateSelectedStudent(studentId: ObjId) {
    const student = this._studentSelectionScope().find(
      (s) => s.id === studentId,
    );
    if (!student) {
      this._selectedStudent.set(null);
    } else this._selectedStudent.set(student);
  }

  updateStudentSelectionScopeFromGuardianStudents(
    students: IGuardianStudent[],
  ) {
    this._studentSelectionScope.set(
      this.mapGuardianStudentsToStudentSelectionScope(students),
    );
  }

  private mapGuardianStudentsToStudentSelectionScope(
    students: IGuardianStudent[],
  ): StudentSelectionScope[] {
    return students.map((student) => {
      const { schoolStructure } = student;

      const {
        school,
        level,
        class: studentClass,
        academicYear,
      } = schoolStructure?.[0] || {};
      return {
        id: student.studentId,
        fullName: student.fullName,
        nationalId: student.nationalId,
        academicYear: academicYear
          ? {
              id: academicYear.id,
              name: academicYear.name,
            }
          : null,
        school: school
          ? {
              id: school.id,
              name: school.name,
              level: level ?? null,
              class: studentClass ?? null,
            }
          : null,
      };
    });
  }
}
